import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { FPSControl } from 'mapbox-gl-fps/lib/MapboxFPS'
import { polygon, featureCollection } from '@turf/helpers'
import axios from 'axios'
import URLSearchParams from '@ungap/url-search-params'
import './setupFontAwesome'
import Ui from './Ui'

// Because parsel doesn't have a feature to disable babel in node_modules,
// we cannot use mapboxgl as an ES module.
// https://github.com/mapbox/mapbox-gl-js/issues/3422

mapboxgl.accessToken = process.env.MAPGL_ACCESS_TOKEN

const ApiUrlBase =
  process.env.API_URL_BASE || 'http://www.econophysics.jp/econovis'
const ScenarioApiUrl = `${ApiUrlBase}/vis/scenarios.json`
const GridApiUrl = `${ApiUrlBase}/vis/vis.json`

const urlParams = new URLSearchParams(window.location.search)
const DAYS = urlParams.has('days') ? parseInt(urlParams.get('days'), 10) : 364
const MIN_ZOOM = 5
const MAX_ZOOM = 10

function zoomLevelToGridSize(zoomLevel, grids) {
  const z = (zoomLevel - MIN_ZOOM) / (MAX_ZOOM - MIN_ZOOM)
  const zz = 1 - Math.pow(1 - z, 3)
  const i = Math.floor(zz * (grids.length - 1))
  return grids[grids.length - 1 - i]
}

function requestScenario() {
  return axios.get(ScenarioApiUrl)
}

function requestGrid({
  scenarioName,
  latitudeStart,
  latitudeEnd,
  longitudeStart,
  longitudeEnd,
  days,
  gridSize,
  startDay = 0,
  cancelToken,
}) {
  return axios.get(
    `${GridApiUrl}
?scenario_name=${scenarioName}
&days=${days}
&start_day=${startDay}
&grid_size=${gridSize}
&latitude_start=${latitudeStart}
&latitude_end=${latitudeEnd}
&longitude_start=${longitudeStart}
&longitude_end=${longitudeEnd}`,
    { cancelToken }
  )
}

function createMap(bounds, center) {
  return new Promise(resolve => {
    const map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/dark-v9',
      minZoom: MIN_ZOOM, // default: 0
      maxZoom: MAX_ZOOM, // default: 22
      hash: true, // default: false
      interactive: true, // default: true
      maxBounds: bounds, // default: undefined
      center, // default: [0, 0]
      renderWorldCopies: true, // default: true
      zoom: 0, // default: 0
      pitch: 0, // default: 0
    })

    map.on('load', () => {
      if (parseInt(process.env.SHOW_FPS_CONTROL, 10)) {
        const fpsControl = new FPSControl()
        map.addControl(fpsControl, 'top-right')
      }

      resolve(map)
    })
  })
}

function makeGrid(data) {
  const dataByDay = []
  for (let day = 0; day < data.days; day++) {
    const polygons = data.grids.map(({ bounds, time_series }) => {
      const coords = [
        [
          [bounds[0], bounds[1]], // lng_min, lat_min
          [bounds[2], bounds[1]], // lng_max, lat_min
          [bounds[2], bounds[3]], // lng_max, lat_max
          [bounds[0], bounds[3]], // lng_min, lat_max
          [bounds[0], bounds[1]], // lng_min, lat_min
        ],
      ]
      const h = 240 * (1.0 - time_series[day][0])
      const s = 50
      const l = 50
      const a = 0.75
      const props = {
        color: `hsla(${h}, ${s}%, ${l}%, ${a})`,
      }
      return polygon(coords, props)
    })
    dataByDay.push(featureCollection(polygons))
  }

  return dataByDay
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scenarios: [],
      selectedScenario: '',
      day: 0,
      numDays: 0,
      isPlaying: false,
      isDataReceived: false,
    }

    this.onDayChange = this.onDayChange.bind(this)
    this.onScenarioChange = this.onScenarioChange.bind(this)
    this.onPlayClick = this.onPlayClick.bind(this)
    this.onPauseClick = this.onPauseClick.bind(this)
    this.onMapIdle = this.onMapIdle.bind(this)
    this.onZoomend = this.onZoomend.bind(this)
    this.updateDay = this.updateDay.bind(this)
    this.updateScenarios = this.updateScenarios.bind(this)
    this.gridSize = this.gridSize.bind(this)
    this.updateGrid = this.updateGrid.bind(this)
  }
  async componentDidMount() {
    const bounds = [
      [120, 22], // sw
      [155, 52], // ne
    ]
    const center = [138.6447145, 36.1754858]
    this.map = await createMap(bounds, center)
    this.map.on('idle', this.onMapIdle)
    this.map.on('zoomend', this.onZoomend)
    this.map.addSource('samples', {
      type: 'geojson',
      data: featureCollection([]),
    })
    this.map.addLayer({
      id: 'samples',
      source: 'samples',
      type: 'fill',
      paint: {
        'fill-color': ['get', 'color'],
      },
    })
    this.gridTable = {}
    this.prevGridSize = 0
    this.cancelToken = null

    await this.updateScenarios()
    await this.updateGrid(this.gridSize())
  }
  updateScenarios() {
    return requestScenario().then(
      scenarioResponse =>
        new Promise(resolve => {
          const scenarios = scenarioResponse.data.scenarios
          const scenarioNames = scenarios.map(scenario => scenario.name)
          this.setState(
            {
              selectedScenario: scenarioNames[0],
              scenarios: scenarioNames,
            },
            resolve
          )
          this.gridTable = scenarios.reduce(
            (acc, { name, grids }) => ({ ...acc, [name]: grids }),
            {}
          )
        })
    )
  }
  gridSize() {
    return zoomLevelToGridSize(
      this.map.getZoom(),
      this.gridTable[this.state.selectedScenario]
    )
  }
  updateGrid(gridSize) {
    this.prevGridSize = gridSize

    return new Promise(resolve =>
      this.setState({ isDataReceived: false }, resolve)
    )
      .then(() => {
        if (this.cancelToken != null) {
          this.cancelToken.cancel()
          this.cancelToken = null
        }

        this.cancelToken = axios.CancelToken.source()

        return requestGrid({
          scenarioName: this.state.selectedScenario,
          days: DAYS,
          latitudeStart: 24.0,
          latitudeEnd: 46.0,
          longitudeStart: 123.0,
          longitudeEnd: 146.0,
          gridSize,
          cancelToken: this.cancelToken.token,
        })
      })
      .then(gridResponse => {
        this.gridByDay = makeGrid(gridResponse.data)
        this.cancelToken = null
        this.map.getSource('samples').setData(this.gridByDay[this.state.day])
        return new Promise(resolve =>
          this.setState(
            {
              numDays: gridResponse.data.days,
              isDataReceived: true,
            },
            resolve
          )
        )
      })
      .catch(thrown => {
        if (!axios.isCancel(thrown)) {
          throw thrown
        }
      })
  }
  onDayChange(ev) {
    const day = parseInt(ev.target.value, 10)
    this.map.getSource('samples').setData(this.gridByDay[day])
    this.setState({ day })
  }
  onScenarioChange(ev) {
    this.setState({ selectedScenario: ev.target.value }, () =>
      this.updateGrid(this.gridSize())
    )
  }
  onPlayClick(ev) {
    this.setState({ isPlaying: true }, () => {
      this.updateDay()
    })
  }
  onPauseClick(ev) {
    this.setState({ isPlaying: false })
  }
  onMapIdle() {
    if (this.state.isPlaying) {
      this.updateDay()
    }
  }
  onZoomend(ev) {
    const gridSize = this.gridSize()
    if (this.prevGridSize !== gridSize) {
      this.updateGrid(gridSize)
    }
  }
  updateDay() {
    const nextDay =
      this.state.numDays === this.state.day + 1 ? 0 : this.state.day + 1
    this.setState({ day: nextDay }, () => {
      this.map.getSource('samples').setData(this.gridByDay[this.state.day])
    })
  }
  render() {
    return (
      <Ui
        day={this.state.day}
        numDays={this.state.numDays}
        scenarios={this.state.scenarios}
        selectedScenario={this.state.selectedScenario}
        isPlaying={this.state.isPlaying}
        isDataReceived={this.state.isDataReceived}
        onDayChange={this.onDayChange}
        onScenarioChange={this.onScenarioChange}
        onPlayClick={this.onPlayClick}
        onPauseClick={this.onPauseClick}
      />
    )
  }
}

ReactDOM.render(<App />, document.getElementById('ui'))
