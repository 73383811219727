import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TitlePanel = styled.div`
  position: absolute;
  left: 2rem;
  top: 1rem;
`

const LegendPanel = styled.div`
  position: absolute;
  right: 3rem;
  top: 1rem;
`

const LegendTitle = styled.span`
  color: white;
  display: block;
  font-size: 1rem;
  transform: translateX(-50%);
`

const LegendBar = styled.div`
  width: 1rem;
  height: 1rem;
  background: hsl(${props => props.hue}, 50%, 50%);
  transform: translateX(-50%);
`

const LegendTickHigh = styled.span`
  color: white;
  font-size: 0.6rem;
  position: absolute;
  left: 1rem;
  bottom: 10.2rem;
`

const LegendTickLow = styled.span`
  color: white;
  font-size: 0.6rem;
  position: absolute;
  left: 1rem;
  bottom: 0;
`

const UsagePanel = styled.dl`
  position: absolute;
  display: table;
  right: 3rem;
  top: 15rem;
  color: white;
  background: rgba(0, 0, 0, 0.4);
  padding: 1rem;
  font-size: 0.8rem;
`

const UsagePanelTitle = styled.h2`
  font-size: 1rem;
  text-align: center;
`

const UsageTitle = styled.div`
  text-align: right;
  margin-right: 1rem;
`

const Title = styled.h1`
  color: white;
  text-shadow: 1px 1px 2px black;
  font-weight: bold;
`

const DayText = styled.p`
  color: white;
  text-shadow: 1px 1px 2px black;
  font-size: 6rem;
  font-weight: bold;
`

const Controls = styled.div`
  pointer-events: auto;
  position: absolute;
  left: 50%;
  bottom: 10%;
  width: 70%;
  transform: translateX(-50%);
  padding: 1rem;
  background: rgba(0, 0, 0, 0.4);
  display: table;
`

const ControlItem = styled.div`
  display: table-cell;
  ${props =>
    props.grow &&
    css`
      width: 100%;
    `}
`

const Spacer = styled.div`
  width: ${props => props.size};
`

const Slider = styled.input`
  position: relative;
  top: 1px;
  width: 100%;
`

const Label = styled.label`
  color: white;
  margin: 0;
  margin-right: 0.3rem;
`

const SpinnerLocator = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

function Spinner() {
  return (
    <SpinnerLocator>
      <FontAwesomeIcon icon="spinner" size="4x" color="white" spin />
    </SpinnerLocator>
  )
}

function PlayButton({ disabled, onClick }) {
  return (
    <span title="Play" onClick={disabled ? undefined : onClick}>
      <FontAwesomeIcon icon="play" color={disabled ? 'gray' : 'white'} />
    </span>
  )
}

function PauseButton({ disabled, onClick }) {
  return (
    <span title="Pause" onClick={disabled ? undefined : onClick}>
      <FontAwesomeIcon icon="pause" color={disabled ? 'gray' : 'white'} />
    </span>
  )
}

export default function Ui({
  day,
  numDays,
  scenarios,
  selectedScenario,
  isPlaying,
  isDataReceived,
  onPlayClick,
  onPauseClick,
  onDayChange,
  onScenarioChange,
}) {
  return (
    <Fragment>
      <TitlePanel>
        <Title>
          Visualization System
          <br />
          for Corporate Activity Data
        </Title>
        <DayText>Day {day + 1}</DayText>
      </TitlePanel>
      <LegendPanel>
        <LegendTitle>Damage</LegendTitle>
        <LegendTickHigh>High</LegendTickHigh>
        <LegendBar hue="0" />
        <LegendBar hue="24" />
        <LegendBar hue="48" />
        <LegendBar hue="72" />
        <LegendBar hue="96" />
        <LegendBar hue="120" />
        <LegendBar hue="144" />
        <LegendBar hue="168" />
        <LegendBar hue="192" />
        <LegendBar hue="216" />
        <LegendBar hue="240" />
        <LegendTickLow>Low</LegendTickLow>
      </LegendPanel>
      <UsagePanel>
        <UsagePanelTitle>Usage</UsagePanelTitle>
        <table>
          <tbody>
            <tr>
              <td>
                <UsageTitle>Mouse drag</UsageTitle>
              </td>
              <td>Move view position</td>
            </tr>
            <tr>
              <td>
                <UsageTitle>Mouse wheel</UsageTitle>
              </td>
              <td>Zoom in/out</td>
            </tr>
            <tr>
              <td>
                <UsageTitle>Select box in the bottom</UsageTitle>
              </td>
              <td>Scenario selection</td>
            </tr>
            <tr>
              <td>
                <UsageTitle>Play button in the bottom</UsageTitle>
              </td>
              <td>Toggle animation</td>
            </tr>
            <tr>
              <td>
                <UsageTitle>Slider in the bottom</UsageTitle>
              </td>
              <td>Move time position by day</td>
            </tr>
          </tbody>
        </table>
      </UsagePanel>
      <Controls>
        <ControlItem style={{ whiteSpace: 'nowrap' }}>
          <Label htmlFor="scenario-select">Scenario</Label>
          <select
            id="scenario-select"
            title="Select a scenario"
            value={selectedScenario}
            disabled={!isDataReceived}
            onChange={onScenarioChange}
          >
            {scenarios.map(scenario => (
              <option key={scenario} value={scenario}>
                {scenario}
              </option>
            ))}
          </select>
        </ControlItem>
        <ControlItem>
          <Spacer size="1rem" />
        </ControlItem>
        <ControlItem>
          {isPlaying ? (
            <PauseButton disabled={!isDataReceived} onClick={onPauseClick} />
          ) : (
            <PlayButton disabled={!isDataReceived} onClick={onPlayClick} />
          )}
        </ControlItem>
        <ControlItem>
          <Spacer size="0.5rem" />
        </ControlItem>
        <ControlItem grow>
          <Slider
            title="Time slider"
            type="range"
            min="0"
            max={numDays - 1}
            step="1"
            value={day}
            disabled={!isDataReceived}
            onChange={onDayChange}
          />
        </ControlItem>
      </Controls>
      {!isDataReceived && <Spinner />}
    </Fragment>
  )
}
